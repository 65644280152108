import React from 'react';

const Slack = () => {
	return (
		<svg
			width={40}
			height={40}
			viewBox='0 0 40 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M30.0744 17.0021C27.8044 9.41214 24.5344 7.65214 16.9944 9.92214C9.4544 12.1921 7.6444 15.4621 9.9144 23.0021C12.1844 30.5421 15.4544 32.3521 22.9944 30.0821C30.5344 27.8121 32.3444 24.5421 30.0744 17.0021Z'
				stroke='#6C5CE7'
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M20.5645 13.9922L24.1445 24.3922'
				stroke='#6C5CE7'
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M15.8444 15.6121L19.4244 26.0121'
				stroke='#6C5CE7'
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M24.3844 15.8521L13.9844 19.4321'
				stroke='#6C5CE7'
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M26.0044 20.572L15.6044 24.152'
				stroke='#6C5CE7'
				strokeWidth={2}
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
};

export default Slack;
